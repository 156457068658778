/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import CircularProgress from '@material-ui/core/CircularProgress'

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import AsyncFetch from '../../../utilities/AsyncFetch'
import UserPermissionRow from './UserPermissionRow'
import ReactSelect from '../../../components/ReactSelect/ReactSelect'

import dropdownScss from '../../CSSModules/Dropdown.scss'
import modalScss from '../../CSSModules/Modal.scss'

import mainModalTheme from '../../../utilities/componentConstants/mainModalTheme'
import { apis } from '../../../config/apiConfig'
import * as utils from '../../../utilities/util'

import { Alert } from '../../../actions'

function SimpleDialog({ onClose, itemId, dataType, dataConfig }) {
  // const [mountMakeDataConfig, setMountMakeDataConfig] = useState(false);
  const user = useSelector(state => state.user)
  const account = utils.objectKeyVal(user.accountID, user.accounts, 'id')
  const dispatch = useDispatch()

  /* ----------------------------- DROPDOWN STATES ---------------------------- */
  const [showRoleDropdown, setRoleDropdown] = useState('')
  const [showOrgRoleDropdown, setOrgRoleDropdown] = useState(false)

  /* ----------------------------- MODAL STATE ---------------------------- */
  const [isOpen, setIsOpen] = useState(true)
  const [userList, setUserList] = useState([])
  const [loading, setLoading] = useState(true)
  const [initialLayer, setInitialLayer] = useState(null)
  const [layerPermissions, setLayerPermissions] = useState(null)
  const [userNames, setUserNames] = useState([])
  const [userID, setUserNameValue] = useState([])
  const [userAddRole, setUserAddRole] = useState('viewer')

  const [initialFetchObjects, setInitialFetchObjects] = useState(null)
  const [layerCallObjects, setLayerCallObjects] = useState(null)
  const [addFetching, setAddFetching] = useState(false)
  const [cancelFetching, setCancelFetching] = useState(false)

  /* ----------------------------- LAYER AND GROUP HIERARCHY STATE  ---------------------------- */
  const [layerHierarchy, setLayerHierarchy] = useState(
    dataConfig.layerHierarchy ||
      dataConfig.filter(res => res.layerHierarchy)[0] ||
      null
  )

  const [itemHierarchy, setItemHierarchy] = useState(null)
  /* ----------------------------- IMAGE HANDLER ---------------------------- */
  const [ownerImgURL, setOwnerImgURL] = useState(
    'https://my-asset-map-data.s3.amazonaws.com/public/client_data/static/images/user.png'
  )
  const [accountImgURL, setAccountImgURL] = useState(
    'https://my-asset-map-data.s3.amazonaws.com/public/client_data/static/images/account.png'
  )
  /* ----------------------------- HANDLER FUNCTIONS ---------------------------- */

  let APIPrefix
  switch (dataType) {
    case 'mapViewGroup':
      APIPrefix = 'mapview/group'
      break
    case 'mapView':
      APIPrefix = 'mapview'
      break
    case 'group':
      APIPrefix = 'group'
      break
    case 'layer':
    default:
      APIPrefix = 'layer'
      break
  }

  const handleClose = () => {
    setIsOpen(false)
    onClose(false)
  }

  const handleAddUsers = () => {
    if (userNames) {
      const url = apis.apiDatabase.uri + APIPrefix + '/permissions/update'
      const method = 'POST'
      const body = {
        layerID: itemId,
        role: userAddRole,
      }

      if (
        userID &&
        userID[0] &&
        typeof userID[0] === 'string' &&
        userID[0].toLowerCase() === 'select all'
      ) {
        // SELECT ALL
        body.userID = userList
          .map(userVal => userVal.value)
          .filter(userVal => userVal !== 'Select All')
        // .filter(userVal => userVal !== user.profile.userID)
      } else {
        // ONE USER
        body.userID = userID
      }

      if (userAddRole === 'no-access') {
        body.role = null
      }
      if (itemHierarchy) body.layerHierarchy = itemHierarchy
      setUserNames([])
      setAddFetching(true)
      setLayerCallObjects([{ url, method, body }])
      dispatch(Alert({ success: 'Users have been added' }))
    } else dispatch(Alert({ error: 'Something has gone wrong' }))
  }

  const handleCancel = () => {
    setCancelFetching(true)

    const url = apis.apiDatabase.uri + APIPrefix + '/permissions/update'
    const method = 'POST'
    const body = {
      layerID: itemId,
      permissions: initialLayer,
    }

    setLayerCallObjects([{ url, method, body }])
  }

  const handleInitialFetchFinished = results => {
    results.forEach(result => {
      if (utils.verifyResult(result)) {
        const { data } = result

        if (data.owner) {
          // permissions call
          if (!initialLayer) setInitialLayer(data)
          setLayerPermissions(data)
          utils
            .getImage(data.owner.profileImg, 'user.png')
            .then(imgURL => setOwnerImgURL(imgURL))
        } else if (data.length) {
          // users call
          const map = utils.objectKeyVal(
            user.mapID,
            utils.resultReturn(result),
            'id'
          )

          const getMapUsers = mapUsersList => {
            return mapUsersList.map(users => ({
              label: users.displayName,
              value: users.id,
            }))
            // .filter(userVal => userVal.value !== user.profile.userID)
          }

          if (map)
            setUserList([
              { label: 'Select All', value: 'Select All' },
              ...getMapUsers(map.users),
            ])
        }
      }

      setInitialFetchObjects(null)
      setLayerCallObjects(null)
      setLoading(false)
      if (cancelFetching) {
        setCancelFetching(false)
        handleClose()
      }
      if (addFetching) {
        setUserNames([])
        setAddFetching(false)
      }
    })
  }

  const handleLayerCallFinished = results => {
    handleInitialFetchFinished(results)
  }

  const handleUpdatePermissions = (layerID, uID, role) => {
    const url = apis.apiDatabase.uri + APIPrefix + '/permissions/update'
    const method = 'POST'
    const body = { layerID, role }

    if (
      uID &&
      uID[0] &&
      typeof uID[0] === 'string' &&
      uID[0].toLowerCase() === 'select all'
    ) {
      // SELECT ALL
      body.userID = userList
        .map(userVal => userVal.value)
        .filter(userVal => userVal !== 'Select All')
      // .filter(userVal => userVal !== user.profile.userID)
    } else {
      // ONE USER
      body.userID = uID
    }

    if (userAddRole === 'no-access') {
      body.role = null
    }

    // if role is null (removing permission) no layer hierarchy is necessary
    if (itemHierarchy && role) body.layerHierarchy = itemHierarchy

    setLayerCallObjects([{ url, method, body }])
    dispatch(Alert({ success: 'Users have been updated' }))
  }

  // const getRole = (role) => {
  //   if (typeof role === 'undefined') role = userAddRole;
  //   if (role === 'owner') return 'Owner'
  //   else if (role === 'viewer') return 'Viewer'
  //   else if (role === 'editor') return 'Editor'
  //   else if (role === 'full-user') return 'Full User'
  //   else if (role === 'no-access') return 'No Access'
  // }

  /* ------------------------------ Initialization Functions ----------------------------- */
  const getPermissionsList = layerObj => {
    const { users, owner, organization } = layerObj

    return (
      <>
        <div
          style={{ marginTop: '32px', borderRadius: '4px 4px 0 0' }}
          className={[modalScss.modalBox, modalScss.tripleGrid].join(' ')}
        >
          <div>Name</div>
          <div>Permission</div>
          <div>&nbsp;</div>
        </div>

        <div
          style={{
            borderRadius: '0px 0px 4px 4px',
            marginBottom: '32px',
            padding: '0',
            maxHeight: 'fit-content',
          }}
          className={[modalScss.modalBox].join(' ')}
        >
          <div className={modalScss.tripleGrid}>
            <div>
              <div className={modalScss.profilePic}>
                <img alt='Owner' src={ownerImgURL}></img>
              </div>
              <span>{owner.displayName}</span>
            </div>
            <span className={modalScss['share-owner-name']}>Owner</span>
            <div>&nbsp;</div>
          </div>

          {/* <div className={modalScss.tripleGrid}>
            /* <div>
              <div className={modalScss.profilePic}>
                <img src={accountImgURL}></img>
              </div>

            </div>
            <div>
              <div
                className={[dropdownScss.dropdown].join(' ')}
                onClick={() => setOrgRoleDropdown(!showOrgRoleDropdown)}
              >
                <span style={{ color: showOrgRoleDropdown ? '#91dfed' : '' }}>
                  {organization}&nbsp;&nbsp;
                </span>
                <FontAwesomeIcon
                  style={{ color: showOrgRoleDropdown ? '#91dfed' : '' }}
                  icon={[
                    'fal',
                    'angle-' + (showOrgRoleDropdown ? 'up' : 'down'),
                  ]}
                  size='1x'
                />
                {showOrgRoleDropdown && (
                  <div
                    className={[
                      dropdownScss.dropdownContent,
                      modalScss.dropdownContent,
                    ].join(' ')}
                  >
                    <div
                      className={[
                        dropdownScss.assignDropdownItem,
                        modalScss.dropdownItem,
                      ].join(' ')}
                    >
                      <div
                        onClick={() =>
                          handleUpdatePermissions(
                            itemId,
                            'organization',
                            'editor'
                          )
                        }
                      >
                        <p style={{ marginBottom: '-7px' }}>
                          {utils.getRole('editor')}
                        </p>
                        <span className={modalScss.dropdownItemSubText}>
                          Create + Edit + View
                        </span>
                      </div>
                    </div>

                    <div
                      className={[
                        dropdownScss.assignDropdownItem,
                        modalScss.dropdownItem,
                      ].join(' ')}
                    >
                      <div
                        onClick={() =>
                          handleUpdatePermissions(
                            itemId,
                            'organization',
                            'viewer'
                          )
                        }
                      >
                        <p style={{ marginBottom: '-7px' }}>
                          {utils.getRole('viewer')}
                        </p>
                        <span className={modalScss.dropdownItemSubText}>
                          Read Only
                        </span>
                      </div>
                    </div>

                    <div
                      className={[
                        dropdownScss.assignDropdownItem,
                        modalScss.dropdownItem,
                      ].join(' ')}
                    >
                      <div
                        onClick={() =>
                          handleUpdatePermissions(
                            itemId,
                            'organization',
                            'no-access'
                          )
                        }
                      >
                        <p style={{ marginBottom: '-7px' }}>
                          {utils.getRole('no-access')}
                        </p>
                        <span className={modalScss.dropdownItemSubText}>
                          Make Unavailable
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
             
            </div>
          </div> */}

          {!loading ? (
            users.length ? (
              users.map(u => (
                <UserPermissionRow
                  key={u.id}
                  itemId={itemId}
                  user={u}
                  handleUpdatePermissions={handleUpdatePermissions}
                  getRole={utils.getRole}
                />
              ))
            ) : null
          ) : (
            <div style={{ marginTop: '12px' }}>
              <SkeletonTheme color='#213445' highlightColor='#1a2937'>
                <Skeleton height={38} count={2} />
              </SkeletonTheme>
            </div>
          )}
        </div>
      </>
    )
  }

  // =====================
  // = HIERARCHY HANDLING =
  // =====================

  useEffect(() => {
    const layerHierarchyObj = layerHierarchy.layerHierarchy || layerHierarchy

    layerHierarchyObj.forEach(tocObject => {
      if (tocObject.unprocessedID === itemId) {
        // items with more than one group path length have a more complex hierarchy
        if (
          (tocObject.groupPath.length > 1 && tocObject.isGroup) ||
          (tocObject.isLayer && tocObject.groupPath.length > 0)
        ) {
          setItemHierarchy(tocObject)
        }
      }
    })
  })

  // =====================
  // = DROPDOWN HANDLING =
  // =====================
  const resetDropdowns = () => {
    setRoleDropdown(false)
  }

  const toggleRoleDropdown = type => {
    resetDropdowns()
    if (showRoleDropdown === type) {
      setRoleDropdown(false)
    } else setRoleDropdown(type)
  }

  const useStyles = makeStyles({
    paperScrollPaper: {
      overflowY: 'visible',
    },
    dialogContentRoot: {
      overflowY: 'visible',
    },
  })

  const classes = useStyles()

  /* ----------------------------- Effects ---------------------------- */

  useEffect(() => {
    let url
    let method
    let body
    const params = []

    method = 'POST'
    url = apis.apiDatabase.uri + 'account/maps'
    body = { accountID: user.accountID }

    params.push({ url, method, body })

    url = apis.apiDatabase.uri + APIPrefix + '/permissions'
    method = 'POST'
    body = { layerID: itemId }
    params.push({ url, method, body })

    // setLoading(true);
    setInitialFetchObjects(params)
  }, [])

  useEffect(() => {
    async function getAccountImage() {
      const imgURL = await utils.getImage(account.profileImg, 'account.png')

      setAccountImgURL(imgURL)
    }
    getAccountImage()
  }, [user])

  /* ----------------------------- Render ---------------------------- */

  return (
    <ThemeProvider theme={mainModalTheme}>
      {initialFetchObjects && (
        <AsyncFetch
          fetchObjects={initialFetchObjects}
          fetchFinished={handleInitialFetchFinished}
        />
      )}
      {layerCallObjects && (
        <AsyncFetch
          fetchObjects={layerCallObjects}
          fetchFinished={handleLayerCallFinished}
        />
      )}
      <Dialog
        onClose={handleClose}
        aria-labelledby='share-dialog'
        // onClick={() => pageClicked()}
        open={isOpen}
        maxWidth='lg'
        classes={{
          paperScrollPaper: classes.paperScrollPaper,
        }}
      >
        <DialogTitle id='share-dialog'>
          Manage Permissions
          <FontAwesomeIcon
            onClick={handleClose}
            icon='times'
            size='sm'
            pull='right'
            style={{ cursor: 'pointer' }}
          />
        </DialogTitle>

        <DialogContent
          classes={{ root: classes.dialogContentRoot }}
          style={{ paddingBottom: '0px' }}
        >
          <span>Share with users from organization</span>
          <div className={modalScss.modalBox}>
            <div
              className={modalScss.modalBoxLeft}
              style={{ marginBottom: '27px' }}
            >
              {/* <Select
                labelId="multiple-name-label"
                id="multiple-name"
                multiple
                value={userNames}
                onChange={handleAddUserChange}
                input={<Input />}
                // MenuProps={MenuProps}
              >
                {userList && userList.map((user) => (
                  <MenuItem key={user.id} value={user.id}>
                    {user.displayName}
                  </MenuItem>
                ))}
              </Select> */}
              <ReactSelect
                options={userList}
                onChange={setUserNameValue}
                defaultValue={userNames}
                multiple
                isFixed
              />
              {/* ----------------------------- ROLE DROPDOWN ---------------------------- */}
              <div
                className={[dropdownScss.dropdown].join(' ')}
                onClick={() => toggleRoleDropdown('add')}
                style={{ padding: '6px' }}
              >
                <span
                  style={{ color: showRoleDropdown === 'add' ? '#91dfed' : '' }}
                >
                  {utils.getRole(undefined, userAddRole)}&nbsp;&nbsp;
                </span>
                <FontAwesomeIcon
                  style={{ color: showRoleDropdown === 'add' ? '#91dfed' : '' }}
                  icon={[
                    'fal',
                    'angle-' + (showRoleDropdown === 'add' ? 'up' : 'down'),
                  ]}
                  size='1x'
                />
                {showRoleDropdown && showRoleDropdown === 'add' && (
                  <div
                    className={[
                      dropdownScss.dropdownContent,
                      modalScss.dropdownContent,
                    ].join(' ')}
                  >
                    <div
                      className={[
                        dropdownScss.assignDropdownItem,
                        modalScss.dropdownItem,
                      ].join(' ')}
                    >
                      <div onClick={() => setUserAddRole('full-user')}>
                        <p style={{ marginBottom: '-7px' }}>
                          {utils.getRole('full-user')}
                        </p>
                        <span className={modalScss.dropdownItemSubText}>
                          Create + Edit + View + Delete
                        </span>
                      </div>
                    </div>

                    <div
                      className={[
                        dropdownScss.assignDropdownItem,
                        modalScss.dropdownItem,
                      ].join(' ')}
                    >
                      <div onClick={() => setUserAddRole('editor')}>
                        <p style={{ marginBottom: '-7px' }}>
                          {utils.getRole('editor')}
                        </p>
                        <span className={modalScss.dropdownItemSubText}>
                          Create + Edit + View
                        </span>
                      </div>
                    </div>

                    <div
                      className={[
                        dropdownScss.assignDropdownItem,
                        modalScss.dropdownItem,
                      ].join(' ')}
                    >
                      <div onClick={() => setUserAddRole('viewer')}>
                        <p style={{ marginBottom: '-7px' }}>
                          {utils.getRole('viewer')}
                        </p>
                        <span className={modalScss.dropdownItemSubText}>
                          View only
                        </span>
                      </div>
                    </div>

                    <div
                      className={[
                        dropdownScss.assignDropdownItem,
                        modalScss.dropdownItem,
                      ].join(' ')}
                    >
                      <div onClick={() => setUserAddRole('no-access')}>
                        <p style={{ marginBottom: '-7px' }}>
                          {utils.getRole('no-access')}
                        </p>
                        <span className={modalScss.dropdownItemSubText}>
                          Remove permissions
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* -------------------------------------------------------------------------- */}
            </div>

            <div className={modalScss.modalButtonRight}>
              <button
                type='button'
                className={modalScss.modalMainButton}
                onClick={addFetching ? null : handleAddUsers}
              >
                {addFetching ? <CircularProgress size={10} /> : 'Add'}
              </button>
            </div>
          </div>

          {!loading ? (
            layerPermissions ? (
              getPermissionsList(layerPermissions)
            ) : null
          ) : (
            <div style={{ marginTop: '12px' }}>
              <SkeletonTheme color='#213445' highlightColor='#1a2937'>
                <Skeleton height={38} count={2} />
              </SkeletonTheme>
            </div>
          )}
        </DialogContent>

        <DialogActions>
          <button
            type='button'
            className={modalScss.modalAltButton}
            onClick={cancelFetching ? null : handleCancel}
          >
            Cancel
          </button>
          <button
            type='button'
            className={modalScss.modalMainButton}
            onClick={cancelFetching ? null : handleClose}
          >
            {cancelFetching ? <CircularProgress size={10} /> : 'Done'}
          </button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

export default function ShareModal(props) {
  return <SimpleDialog {...props} />
}
