import environment from './base'

const url = 'https://rg91b1juf3.execute-api.us-east-1.amazonaws.com/dev/'
const keys = {
  google: 'AIzaSyB5HpWvy278epGy3XcKNX-qkgZDQeWmcjY', // dev local google api key
  agGrid:
    'CompanyName=MyAssetMap,LicensedApplication=MyAssetMap,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-011367,ExpiryDate=22_October_2021_[v2]_MTYzNDg1NzIwMDAwMA==8ee51042d12d1f00dc41e3b2da595d2f',
}
const env = environment(url, keys)

export default {
  ...env,
  apis: {
    ...env.apis,
  },
  keys: {
    ...env.keys,
  },
  env: 'dev',
  isPrd: false,
  isQa: false,
  isDev: true,
}
